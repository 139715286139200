import axios from 'axios';
import { PRODUCT_LIST_FAIL, PRODUCT_LIST_REQUEST, PRODUCT_LIST_SUCCESS, PRODUCT_DETAILS_FAIL, PRODUCT_DETAILS_REQUEST, PRODUCT_DETAILS_SUCCESS, PRODUCT_CREATE_REVIEW_REQUEST, PRODUCT_CREATE_REVIEW_SUCCESS, PRODUCT_CREATE_REVIEW_FAIL, PRODUCTS_LIST_REQUEST, PRODUCTS_LIST_SUCCESS, PRODUCTS_LIST_FAIL, PRODUCT_TOP_REQUEST, PRODUCT_TOP_SUCCESS, PRODUCT_TOP_FAIL } from '../Constants/ProductConstants';
import { logout } from './userActions';
import { URL } from '../Url';

// PRODUCT LIST
export const listProduct =
	(keyword = ' ', pageNumber = ' ') =>
	async (dispatch) => {
		try {
			dispatch({ type: PRODUCT_LIST_REQUEST });
			const { data } = await axios.get(`${URL}/api/products?keyword=${keyword}&pageNumber=${pageNumber}`);
			dispatch({ type: PRODUCT_LIST_SUCCESS, payload: data });
		} catch (error: any) {
			dispatch({
				type: PRODUCT_LIST_FAIL,
				payload: error.message && error.response.data.message ? error.response.data.message : error.message
			});
		}
	};
//PRODUCT LIST BY LIGA
export const listProductByLiga = (keyword = ' ', pageNumber = ' ') => async (dispatch) => {
	try {
		dispatch({ type: PRODUCTS_LIST_REQUEST });
		const { data } = await axios.get(`${URL}/api/products/liga?keyword=${keyword}&pageNumber=${pageNumber}`);
		dispatch({ type: PRODUCTS_LIST_SUCCESS, payload: data });
	} catch (error: any) {
		dispatch({
			type: PRODUCTS_LIST_FAIL,
			payload: error.message && error.response.data.message ? error.response.data.message : error.message
		});
	}
};
// TOP SELLING PRODUCTS
export const listTopProducts = () => async (dispatch) => {
	try {
		dispatch({ type: PRODUCT_TOP_REQUEST });
		const { data } = await axios.get(`${URL}/api/products/top`);
		dispatch({ type: PRODUCT_TOP_SUCCESS, payload: data });
	} catch (error: any) {
		dispatch({
			type: PRODUCT_TOP_FAIL,
			payload: error.message && error.response.data.message ? error.response.data.message : error.message
		});
	}
};

// SINGLE PRODUCT
export const listProductDetails = (id) => async (dispatch) => {
	try {
		dispatch({ type: PRODUCT_DETAILS_REQUEST });
		const { data } = await axios.get(`${URL}/api/products/${id}`);
		dispatch({ type: PRODUCT_DETAILS_SUCCESS, payload: data });
	} catch (error: any) {
		dispatch({
			type: PRODUCT_DETAILS_FAIL,
			payload: error.message && error.response.data.message ? error.response.data.message : error.message
		});
	}
};

// PRODUCT REVIEW CREATE
export const createProductReview = (productId, review) => async (dispatch, getState) => {
	try {
		dispatch({ type: PRODUCT_CREATE_REVIEW_REQUEST });

		const {
			userLogin: { userInfo }
		} = getState();

		const config = {
			headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${userInfo.token}`
			}
		};

		await axios.post(`${URL}/api/products/${productId}/review`, review, config);
		dispatch({ type: PRODUCT_CREATE_REVIEW_SUCCESS });
		localStorage.removeItem('cartItems');
	} catch (error: any) {
		const message = error.message && error.response.data.message ? error.response.data.message : error.message;
		if (message === 'Not authorized, token failed') {
			dispatch(logout());
		}
		dispatch({
			type: PRODUCT_CREATE_REVIEW_FAIL,
			payload: message
		});
	}
};
